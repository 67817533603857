<template>
  <div class="remuneration-preview card" @click="$emit('click', $event)">
    <div class="body">
      <span v-if="remuneration.preview.performer">
        {{ remuneration.preview.performer.display_name }}
      </span>
      <span v-else class="empty">Performer</span>

      <div v-if="remuneration.preview.territory">
        <span>
          {{ remuneration.preview.territory.name }} ({{ remuneration.preview.territory.iso }})
        </span>
        <span class="field">Territory</span>
      </div>

      <div v-if="remuneration.year">
        <span>{{ remuneration.year }}</span>
        <span class="field">Year</span>
      </div>

      <div v-if="remuneration.net">
        <span>{{ remuneration.net }}</span>
        <span class="field">Net (SEK)</span>
      </div>

      <div v-if="remuneration.tax">
        <span>
          {{ oppositeSign(remuneration.net, remuneration.tax) }}
        </span>
        <span class="field">Tax (SEK)</span>
      </div>

      <div v-if="remuneration.fee">
        <span>
          {{ oppositeSign(remuneration.net, remuneration.fee) }}
        </span>
        <span class="field">Fee (SEK)</span>
      </div>
    </div>

    <div class="actions">
      <i class="fas fa-trash" @click="$emit('remove')"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    remuneration: {
      type: Object,
      required: true,
    },
  },

  methods: {
    oppositeSign(a, b) {
      a = Number.parseFloat(a);
      b = Number.parseFloat(b);

      const normalizedB = Math.abs(b);

      if (a > 0) {
        return -normalizedB;
      }
      return normalizedB;
    },
  },
};
</script>

<style lang="scss" scoped>
.remuneration-preview {
  --background-color-hover: rgb(245, 245, 245);

  position: relative;
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.08);
  border-right: 4px solid transparent;
  cursor: pointer;

  &:hover {
    background-color: var(--background-color-hover);
  }

  .body {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;

    > * {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &:not(:first-child) {
        padding-top: 0.5rem;
        border-top: 1px solid rgb(230, 230, 230);
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  i {
    padding: 0.75rem;
  }

  &.active {
    border-color: #6d7ddd;
  }

  .actions {
    display: flex;
    align-items: center;

    font-size: 1.25rem;

    margin-left: 1rem;
    padding: 0.2rem;

    i {
      &:hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }
}

.empty,
.field {
  color: gray;
  font-size: 80%;
}

.field {
  display: flex;
  align-items: center;
}
</style>
