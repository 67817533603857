<template>
  <form class="card create-invoices" @submit.prevent="onSubmit">
    <h3>Create invoices</h3>
    <hr />
    <p>Manually trigger the invoice generation process</p>
    <Button type="submit" :loading="loading">Trigger</Button>
  </form>
</template>

<script>
import Button from '@/components/ui/button/Button.vue';
import { mutate } from '../../services/apolloRequest';
import { paymentCommand } from '../../store/modules/payment/store';
import * as uuid from 'uuid';
export default {
  components: {
    Button,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      await mutate(
        paymentCommand({
          stream_id: uuid.v4(),
          type: 'invoice/process-queue',
          payload: {},
        }),
      );
      this.loading = false;
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 0;
}
.create-invoices {
  max-width: 40ch;
}
</style>
