<template>
  <form class="card" @submit.prevent="onSubmit">
    <h3>Reset database</h3>
    <hr />

    <p>Resets events and projected views - and after that the service is restarted</p>

    <Button type="submit" :loading="loading">Reset</Button>
  </form>
</template>

<script>
import { mutate } from '@/services/apolloRequest';
import * as uuid from 'uuid';

import Button from '@/components/ui/button/Button.vue';
import { paymentCommand } from '../../store/modules/payment/store';

export default {
  components: {
    Button,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        await mutate(
          paymentCommand({
            stream_id: uuid.v4(),
            type: 'test/reset',
            payload: {},
          }),
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 0;
  max-width: 40ch;
}

button[type='submit'] {
  margin-left: auto;
}
</style>
