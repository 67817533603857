<template>
  <div class="remunerations">
    <form class="card" @submit.prevent.self="onSubmit">
      <h3>Remunerations</h3>
      <hr />

      <template v-for="(remuneration, i) in remunerations">
        <RemunerationPreview
          :key="i"
          :remuneration="remuneration"
          :class="{ active: selectedRemuneration === remuneration }"
          @click="onRemunerationClick(remuneration)"
          @remove="onRemunerationRemove(remuneration)"
        ></RemunerationPreview>
      </template>

      <Button class="add" @click="addRemuneration">Add remuneration</Button>

      <Button type="submit" :loading="saving">Save</Button>
    </form>

    <template v-if="selectedRemuneration">
      <Remuneration class="card" :remuneration="selectedRemuneration"></Remuneration>
    </template>
  </div>
</template>

<script>
import { mutate } from '@/services/apolloRequest';
import * as uuid from 'uuid';

import Button from '@/components/ui/button/Button.vue';

import Remuneration from './Remuneration.vue';
import RemunerationPreview from './RemunerationPreview.vue';
import { paymentCommand } from '../../store/modules/payment/store';

export default {
  components: {
    Button,
    Remuneration,
    RemunerationPreview,
  },

  data: () => ({
    remunerations: [],
    selectedRemuneration: undefined,
    saving: false,
  }),

  created() {
    this.addRemuneration();
  },

  methods: {
    addRemuneration() {
      const remuneration = {
        performerId: undefined,
        year: undefined,
        tax: undefined,
        fee: undefined,
        net: undefined,

        distributionType: undefined,
        areaId: undefined,
        right: undefined,
        activity: undefined,
        conditionalAspect: undefined,
        sourceId: undefined,

        preview: {
          randomized: false,
          performer: undefined,
          territory: undefined,
        },
      };
      this.remunerations.push(remuneration);

      if (!this.selectedRemuneration) {
        this.onRemunerationClick(remuneration);
      }
    },

    async onSubmit() {
      function oppositeSign(a, b) {
        a = Number.parseFloat(a);
        b = Number.parseFloat(b);

        const normalizedB = Math.abs(b);

        if (a > 0) {
          return -normalizedB;
        }
        return normalizedB;
      }

      const remunerations = this.remunerations.map((remuneration, idx) => {
        // eslint-disable-next-line no-unused-vars
        const { preview, randomized, ...payload } = remuneration;
        const now = new Date();
        return {
          ...payload,
          rowId: -Number([now.getFullYear(), now.getMonth(), now.getSeconds(), idx].join('')),
          net: Number.parseFloat(remuneration.net) * 100,
          fee: oppositeSign(remuneration.net, remuneration.fee) * 100,
          tax: oppositeSign(remuneration.net, remuneration.tax) * 100,
        };
      });

      try {
        this.saving = true;
        await mutate(
          paymentCommand({
            stream_id: uuid.v4(),
            type: 'test/deposit',
            payload: {
              remunerations,
            },
          }),
        );
        this.remunerations = [];
        this.selectedRemuneration = null;
        this.addRemuneration();
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.saving = false;
      }
    },

    onRemunerationClick(remuneration) {
      this.selectedRemuneration = remuneration;
    },

    onRemunerationRemove(remuneration) {
      const index = this.remunerations.indexOf(remuneration);

      this.remunerations.splice(index, 1);

      if (this.selectedRemuneration === remuneration) {
        this.selectedRemuneration = undefined;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.remunerations {
  flex-direction: row;
  gap: var(--gap);
}

.card {
  height: fit-content;
  min-width: 50ch;
}

button {
  display: block;
}

button.add {
  width: fit-content;
}

button[type='submit'] {
  margin-top: 1rem;
  margin-left: auto;
}
</style>
