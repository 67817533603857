<template>
  <div class="payment">
    <h2>Payment</h2>

    <Remunerations></Remunerations>
    <Reset></Reset>
    <TriggerInvoices />

    <div v-if="false" class="card subscription-state">show subscription-state status?</div>
  </div>
</template>

<script>
import Remunerations from './Remunerations.vue';
import Reset from './Reset.vue';
import TriggerInvoices from './TriggerInvoices.vue';

export default {
  components: {
    Remunerations,
    Reset,
    TriggerInvoices,
  },
};
</script>

<style lang="scss" scoped>
.payment {
  --gap: 1rem;

  display: flex;
  flex-flow: row wrap;
  gap: var(--gap);

  h2 {
    flex-basis: 100%;
  }

  > * {
    flex: 0 0 auto;
    height: fit-content;

    display: flex;
    flex-direction: column;
  }

  .subscription-state {
    flex-basis: 100%;
  }
}
</style>
