<template>
  <form class="remuneration" @submit.prevent>
    <h3>Create remuneration</h3>
    <hr />

    <div>
      <SearchableInput
        placeholder="Name, social security number or IPN"
        name="remuneration.performer"
        label="Performer"
        :searcher="SearchHelper.nameSearchPerformer"
        use-template="performerNameSearch"
        :value="remuneration.preview.performer ? remuneration.preview.performer.display_name : null"
        rule="required"
        @input="onPerformerInput"
      />

      <SelectCountry
        return-object
        name="territory"
        label="Territory"
        :value="remuneration.territory"
        @input="onTerritoryInput"
      />

      <ValidatedTextInput
        label="Year"
        name="year"
        type="number"
        :value="remuneration.year"
        @input="onYearInput"
      ></ValidatedTextInput>

      <ValidatedTextInput
        label="Net (SEK)"
        name="net"
        type="number"
        :value="remuneration.net"
        @input="onNetInput"
      ></ValidatedTextInput>

      <ValidatedTextInput
        label="Tax (SEK)"
        name="tax"
        type="number"
        :value="remuneration.tax"
        @input="onTaxInput"
      ></ValidatedTextInput>

      <ValidatedTextInput
        label="Fee (SEK)"
        name="fee"
        type="number"
        :value="remuneration.fee"
        @input="onFeeInput"
      ></ValidatedTextInput>
    </div>

    <Button @click="showAllFields = !showAllFields">Toggle fields</Button>

    <div v-show="showAllFields">
      <SelectInput
        return-object
        name="distribution-type"
        label="Distribution type"
        :items="distributionTypes"
        :value="remuneration.distributionType"
        @input="onDistributionTypeInput"
      />

      <SelectDistributionArea
        return-object
        :on-loaded="(data) => assignRandomDistributionArea(data)"
        :value="remuneration.areaId"
        @input="onDistributionAreaInput"
      />

      <SelectDistributionAreaRight
        return-object
        :on-loaded="(data) => assignRandomDistributionAreaRight(data)"
        :value="remuneration.right"
        @input="onDistributionAreaRightInput"
      />

      <SelectInput
        return-object
        name="performance-activity"
        label="Activity"
        :items="performanceActivities"
        :value="remuneration.activity"
        @input="onPerformanceActivitiyInput"
      />

      <SelectInput
        return-object
        name="conditional-aspect"
        label="Conditional aspect"
        :items="conditionalAspects"
        :value="remuneration.conditionalAspect"
        @input="onConditionalAspectInput"
      />

      <SelectInput
        return-object
        name="revenue-source"
        label="Revenue source"
        :items="singleton.revenueSources"
        :value="remuneration.sourceId"
        @input="onRevenueSourceInput"
      />
    </div>
  </form>
</template>

<script>
import DistributionService from '@/services/distributionService';

import Button from '@/components/ui/button/Button.vue';

import SearchHelper from '@/components/search/searchHelper';
import SelectCountry from '@/components/ui/select/select-country.vue';
import SelectInput from '@/components/ui/select/select-input.vue';
import SearchableInput from '@/components/ui/input/searchable-input.vue';
import SelectDistributionArea from '@/components/ui/select/select-distribution-area.vue';
import SelectDistributionAreaRight from '@/components/ui/select/select-distribution-area-right.vue';

import ValidatedTextInput from '@/components/ui/input/validated-text-input.vue';

const singleton = {
  revenueSources: [],
  distributionAreas: [],
  distributionAreaRights: [],
};

export default {
  components: {
    Button,
    SelectCountry,
    SelectInput,
    SearchableInput,
    SelectDistributionArea,
    SelectDistributionAreaRight,
    ValidatedTextInput,
  },

  props: {
    remuneration: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    showAllFields: false,
    SearchHelper,
    distributionTypes: [
      { code: 'D', name: 'Distribution' },
      { code: 'C', name: 'Correction' },
      { code: 'R', name: 'ReserveRelease' },
      { code: 'S', name: 'Statement' },
    ],
    performanceActivities: [
      { code: '_', name: 'Default' },
      { code: 'M', name: 'Musician' },
      { code: 'A', name: 'Actor' },
      { code: 'S', name: 'Singer' },
    ],
    conditionalAspects: [
      { code: '_', name: 'Default' },
      { code: 'F', name: 'Featured artist' },
      { code: 'N', name: 'Non featured artist' },
    ],
    singleton,
  }),

  watch: {
    remuneration() {
      this.assignRandomValues();
    },
  },

  async mounted() {
    if (singleton.revenueSources.length === 0) {
      const { sources } = await DistributionService.getRevenueSources();

      singleton.revenueSources = sources.map((source) => ({
        code: Number.parseInt(source.id),
        name: source.name,
      }));
    }

    this.assignRandomValues();
  },

  methods: {
    assignRandomValues() {
      if (this.remuneration.randomized) return;
      this.remuneration.randomized = true;

      this.assignRandomValue(this.distributionTypes, (distributionType) =>
        this.onDistributionTypeInput(distributionType),
      );
      this.assignRandomValue(this.singleton.distributionAreas, (distributionArea) =>
        this.onDistributionAreaInput(distributionArea),
      );
      this.assignRandomValue(this.singleton.distributionAreaRights, (distributionAreaRight) =>
        this.onDistributionAreaRightInput(distributionAreaRight),
      );
      this.assignRandomValue(this.performanceActivities, (activity) =>
        this.onPerformanceActivitiyInput(activity),
      );
      this.assignRandomValue(this.conditionalAspects, (conditionalAspect) =>
        this.onConditionalAspectInput(conditionalAspect),
      );
      this.assignRandomValue(this.singleton.revenueSources, (revenueSource) =>
        this.onRevenueSourceInput(revenueSource),
      );
    },

    assignRandomValue(values, assignFn) {
      function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

      const randomIndex = randomIntFromInterval(0, values.length - 1);
      const value = values[randomIndex];

      assignFn(value);
    },

    assignRandomDistributionArea(distributionAreas) {
      this.singleton.distributionAreas = distributionAreas;
      this.assignRandomValue(distributionAreas, (distributionArea) =>
        this.onDistributionAreaInput(distributionArea),
      );
    },

    assignRandomDistributionAreaRight(distributionAreaRights) {
      this.singleton.distributionAreaRights = distributionAreaRights;
      this.assignRandomValue(distributionAreaRights, (distributionAreaRight) =>
        this.onDistributionAreaRightInput(distributionAreaRight),
      );
    },

    onPerformerInput(performer) {
      this.remuneration.performerId = performer?.id;
      this.remuneration.preview.performer = performer;
    },

    onYearInput(year) {
      this.remuneration.year = Number.parseInt(year);
    },

    onNetInput(net) {
      this.remuneration.net = net;
    },

    onTaxInput(tax) {
      this.remuneration.tax = tax;
    },

    onFeeInput(fee) {
      this.remuneration.fee = fee;
    },

    onTerritoryInput(territory) {
      this.remuneration.territory = territory.iso;
      this.remuneration.preview.territory = territory;
    },

    onDistributionAreaInput(distributionArea) {
      this.remuneration.areaId = distributionArea?.id;
    },

    onDistributionAreaRightInput(distributionAreaRight) {
      this.remuneration.right = distributionAreaRight?.code;
    },

    onDistributionTypeInput(distributionType) {
      this.remuneration.distributionType = distributionType?.code;
    },

    onPerformanceActivitiyInput(activity) {
      this.remuneration.activity = activity.code;
    },

    onConditionalAspectInput(conditionalAspect) {
      this.remuneration.conditionalAspect = conditionalAspect.code;
    },

    onRevenueSourceInput(revenueSource) {
      this.remuneration.sourceId = revenueSource.code;
    },
  },
};
</script>

<style lang="scss" scoped>
.remuneration {
  margin-bottom: 1rem;

  > div {
    width: 60ch;

    & > div {
      margin-bottom: 1.2rem;
    }
  }
}

button {
  margin-bottom: 0.5rem;
}
</style>
