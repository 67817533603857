<template>
  <button :type="type" class="btn" @click="$emit('click', $event)">
    <slot />
    <template v-if="innerLoading">
      <i class="fas fa-spinner fa-spin"></i>
    </template>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      innerLoading: this.loading,
    };
  },

  watch: {
    loading(loading) {
      if (loading) {
        this.innerLoading = loading;
        return;
      }

      setTimeout(() => {
        this.innerLoading = false;
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
i {
  margin-left: 0.5rem;
}
</style>
